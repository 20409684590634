import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import MenuConfig from "../../../_helpers/MenuConfig";
import { toAbsoluteUrl } from "../../../_helpers/utils";

import Button from "../../Button";

import styles from "./HeaderLinks.module.scss";

import { KeyboardArrowDown } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";

const HeaderLinks = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <nav className={styles.headerLinks}>
            <div className={styles.headerLinks__items}>
                <div className={styles.headerLinks__items}>
                    <div key="5" className={styles.footer__topLinks}>
                        <a href="https://developer.everest.org">Developers</a>
                    </div>
                    {MenuConfig.header.menuItem.map((menu) => (
                        <div key={menu.id} className={styles.footer__topLinks}>
                            {!menu.page && menu.subMenu.length > 0 ? (
                                <>
                                    <a
                                        href="javascript:void(0);"
                                        onMouseEnter={handleOpenMenu}
                                    >
                                        {menu.title}{" "}
                                        <KeyboardArrowDown
                                            style={{
                                                verticalAlign: "middle",
                                                marginTop: "-4px",
                                            }}
                                        />
                                    </a>
                                    <Menu
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleCloseMenu}
                                        MenuListProps={{
                                            onMouseLeave: handleCloseMenu,
                                        }}
                                        style={{ marginTop: "35px" }}
                                        disableScrollLock
                                        keepMounted
                                    >
                                        {menu.subMenu.map((subMenu) => (
                                            <MenuItem key={subMenu.id}>
                                                <HashLink
                                                    className={`${styles.headerLinks__items__menu} ${styles.headerLinks__items__sub_menu}`}
                                                    to={subMenu.page}
                                                >
                                                    {subMenu.title}
                                                </HashLink>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            ) : (
                                <HashLink
                                    // activeClassName={styles.headerLinks__menuActive}
                                    to={menu.page}
                                >
                                    {menu.title}
                                </HashLink>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Button */}

            <div className={styles.headerLinks__btn}>
                <Button
                    variant="secondary"
                    text="Contact Us"
                    page="mailto:contact@everest.org"
                    external
                />
                <Button
                    variant="tertiary"
                    text="Sign In"
                    page="https://wallet.everest.org/"
                    external
                />
            </div>

            {/* Social Icons */}

            <div className={styles.headerLinks__social}>
                {MenuConfig.header.socialPages.map((social) => (
                    <div key={social.id}>
                        <a
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={toAbsoluteUrl(social.icon.header)}
                                alt={social.title}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </nav>
    );
};

export default HeaderLinks;
